/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import { getLinkAddress } from 'utilities/helpers';
import { Article, InsertDriveFile } from '@mui/icons-material';
import { useRecentActivityQuery } from 'services/private/recentActivity';
import { darkGrey } from 'styles/mui/themeVariables';
import useAuthInfo from 'customHooks/useAuthInfo';
// import RecentActivityIcon from 'assets/svg/time-fast.svg';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function RecentActivity() {
  const [isOpen, setIsOpen] = useState(null);

  const primaryColor = useGetThemeColor();

  const { company, allowedModules } = useAuthInfo();
  const isSalesSection = allowedModules?.find(module => (module?.section === 'Sales'));
  const isPurchaseSection = allowedModules?.find(module => (module?.section === 'Purchase'));

  const { data: recentActivity = [] } = useRecentActivityQuery({ company: company?.id });
  const handleMenuClick = e => {
    setIsOpen(e.currentTarget);
  };
  const handleClose = () => {
    setIsOpen(null);
  };

  const getActivityIcon = type => {
    switch (type) {
      case 'Invoice' || 'Invoice Payment' || 'Credit Note' || 'Payment Voucher':
        return <InsertDriveFile style={{ color: darkGrey, fontSize: '20px' }} />;
      case 'Account' || 'Customer' || 'Supplier':
        return <PersonIcon style={{ color: darkGrey, fontSize: '20px' }} />;
      default:
        return <Article style={{ color: darkGrey, fontSize: '20px' }} />;
    }
  };

  return (
    <>
      {(isSalesSection || isPurchaseSection) && (
      <Tooltip title="Recent Activity" placement="bottom">
        <IconButton className="svg-icon-btn" onClick={handleMenuClick}>
          {/* <img src={RecentActivityIcon} alt="Recent Activity" height={18} width={18} /> */}
          <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_501_91)">
              <path d="M5.625 15H0.625C0.45924 15 0.300269 14.9342 0.183058 14.8169C0.065848 14.6997 0 14.5408 0 14.375C0 14.2092 0.065848 14.0503 0.183058 13.9331C0.300269 13.8158 0.45924 13.75 0.625 13.75H5.625C5.79076 13.75 5.94973 13.8158 6.06694 13.9331C6.18415 14.0503 6.25 14.2092 6.25 14.375C6.25 14.5408 6.18415 14.6997 6.06694 14.8169C5.94973 14.9342 5.79076 15 5.625 15Z" className="svg-icon" />
              <path d="M4.375 12.5H0.625C0.45924 12.5 0.300269 12.4342 0.183058 12.3169C0.065848 12.1997 0 12.0408 0 11.875C0 11.7092 0.065848 11.5503 0.183058 11.4331C0.300269 11.3158 0.45924 11.25 0.625 11.25H4.375C4.54076 11.25 4.69973 11.3158 4.81694 11.4331C4.93415 11.5503 5 11.7092 5 11.875C5 12.0408 4.93415 12.1997 4.81694 12.3169C4.69973 12.4342 4.54076 12.5 4.375 12.5Z" className="svg-icon" />
              <path d="M3.125 10H0.625C0.45924 10 0.300269 9.93415 0.183058 9.81694C0.065848 9.69973 0 9.54076 0 9.375C0 9.20924 0.065848 9.05027 0.183058 8.93306C0.300269 8.81585 0.45924 8.75 0.625 8.75H3.125C3.29076 8.75 3.44973 8.81585 3.56694 8.93306C3.68415 9.05027 3.75 9.20924 3.75 9.375C3.75 9.54076 3.68415 9.69973 3.56694 9.81694C3.44973 9.93415 3.29076 10 3.125 10Z" className="svg-icon" />
              <path d="M8.12499 14.9719C7.95923 14.9793 7.79733 14.9205 7.6749 14.8085C7.55248 14.6965 7.47955 14.5405 7.47218 14.3747C7.4648 14.209 7.52357 14.0471 7.63557 13.9246C7.74756 13.8022 7.9036 13.7293 8.06936 13.7219C9.25215 13.6137 10.3796 13.1707 11.3197 12.4448C12.2598 11.7189 12.9736 10.7401 13.3774 9.62318C13.7813 8.50623 13.8586 7.29732 13.6001 6.13805C13.3417 4.97877 12.7583 3.91714 11.9183 3.07748C11.0782 2.23782 10.0163 1.65491 8.85694 1.39702C7.69755 1.13913 6.48867 1.21694 5.37191 1.62133C4.25514 2.02572 3.27673 2.73995 2.55125 3.68037C1.82578 4.62079 1.38329 5.74845 1.27561 6.93129C1.2607 7.09639 1.1808 7.2488 1.05351 7.35499C0.926223 7.46118 0.761961 7.51246 0.596864 7.49754C0.431767 7.48262 0.279358 7.40273 0.173165 7.27544C0.066973 7.14815 0.0156957 6.98389 0.0306142 6.81879C0.20492 4.89586 1.11412 3.11423 2.56894 1.84477C4.02377 0.57531 5.91214 -0.0841686 7.84096 0.00361121C9.76978 0.091391 11.5904 0.919666 12.9239 2.31602C14.2575 3.71238 15.0011 5.56922 15 7.50004C15.0096 9.374 14.3134 11.1829 13.0499 12.5669C11.7865 13.9509 10.0483 14.8086 8.18124 14.9694C8.16249 14.9713 8.14311 14.9719 8.12499 14.9719Z" className="svg-icon" />
              <path d="M7.5 3.75C7.33424 3.75 7.17527 3.81585 7.05806 3.93306C6.94085 4.05027 6.875 4.20924 6.875 4.375V7.5C6.87504 7.66575 6.94091 7.82469 7.05813 7.94187L8.93313 9.81687C9.051 9.93072 9.20888 9.99372 9.37275 9.9923C9.53662 9.99087 9.69338 9.92514 9.80926 9.80926C9.92514 9.69338 9.99087 9.53662 9.9923 9.37275C9.99372 9.20888 9.93072 9.051 9.81688 8.93313L8.125 7.24125V4.375C8.125 4.20924 8.05915 4.05027 7.94194 3.93306C7.82473 3.81585 7.66576 3.75 7.5 3.75Z" className="svg-icon" />
            </g>
            <defs>
              <clipPath id="clip0_501_91">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </IconButton>
      </Tooltip>
      )}

      <Menu
        key={isOpen}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={handleClose}
        sx={{
          top: 50,
          '& li:hover': {
            backgroundColor: primaryColor,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {recentActivity?.results?.slice(0, 10).map((activity, index) => (
          <Link to={getLinkAddress(activity.type, activity.object_id)} onClick={handleClose} key={`${activity.object_id}-${index}`}>
            <MenuItem
              key={activity.object_id}
            >
              <Box className="d-flex align-items-center justify-content-between px-2 gap-3">
                <Box>{getActivityIcon(activity.type)}</Box>
                <Stack>
                  <Typography variant="body2">{activity.title}</Typography>
                  <Typography variant="smallText">{activity.type}</Typography>
                </Stack>
              </Box>
            </MenuItem>
          </Link>
        ))}

        {recentActivity?.results?.length === 0 && (
          <MenuItem>
            <Typography variant="body2">No activity found</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default RecentActivity;
