import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  collapse: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    collapseSidebar: state => ({ ...state, collapse: true }),
    expandSidebar: state => ({ ...state, collapse: false }),
    toggleSidebar: state => ({ ...state, collapse: !state.collapse }),
  },
});

export const { collapseSidebar, expandSidebar, toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
