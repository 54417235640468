import React, { useCallback, useMemo } from 'react';
import { FormHelperText, Typography, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useField } from 'formik';

import { fieldStyles } from 'styles/containers/selectStyles';
import useGetThemeColor from 'customHooks/useGetThemeColor';

const CUSTOM_BUTTON_VALUE = 'custom-menu-button';

function FormikSelect({
  name,
  options,
  disabled,
  onChange,
  onBlur,
  isGrouped,
  isClearable,
  onMenuCustomButtonClick,
  menuCustomButtonLabel,
  label,
  placeholder,
  menuPosition,
  menuPlacement,
  menuShouldBlockScroll,
  formatOptionLabel,
  isRequired,
  isRow,
  classes,
  isOpen,
  depenpencyArray,
  onKeyDown
}) {
  const [field, meta, helpers] = useField(name);

  const { value, onBlur: onFieldBlur } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  const primaryColor = useGetThemeColor();

  const handleChange = useCallback(selectedOption => {
    if (selectedOption?.value === CUSTOM_BUTTON_VALUE) return;

    const fieldValue = selectedOption?.value || null;
    setValue(fieldValue);

    if (onChange) onChange(fieldValue);
  }, [...depenpencyArray]);

  const handleBlur = useCallback(event => {
    onFieldBlur(event);

    if (onBlur) onBlur(name, event);
  }, [...depenpencyArray, value]);

  const allOptions = useMemo(
    () => (isGrouped ? options.map(item => item.options).flatMap(item => item) : [...options]),
    [options]
  );
  const selectedOption = useMemo(
    () => allOptions.find(option => option?.value === value ?? ''),
    [allOptions, value]
  );

  const customButtonOption = {
    label: (
      <Button color="primary" onClick={onMenuCustomButtonClick}>
        {menuCustomButtonLabel}
      </Button>
    ),
    value: CUSTOM_BUTTON_VALUE,
  };

  const modifiedOptions = useMemo(
    () => [...options, ...(onMenuCustomButtonClick ? [customButtonOption] : [])],
    [options, onMenuCustomButtonClick]
  );

  return (
    <Grid
      className={classes}
      sx={{ '& .react-select__option--is-selected, & .react-select__option--is-selected:hover': { backgroundColor: primaryColor } }}
      spacing={1}
      container
    >
      <Grid
        className="d-flex align-items-center"
        item
        xl={isRow ? 3 : 12}
        lg={isRow ? 3 : 12}
        md={isRow ? 4 : 12}
        sm={12}
      >
        {label && (
          <Typography
            className={isRequired ? 'required' : ''}
            variant="body2"
            sx={{ mb: '2px !important' }}
          >
            {label}
          </Typography>
        )}
      </Grid>
      <Grid item xl={isRow ? 9 : 12} lg={isRow ? 9 : 12} md={isRow ? 8 : 12} sm={12}>
        <Select
          autoComplete="false"
          menuIsOpen={isOpen}
          options={modifiedOptions}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          value={selectedOption || null}
          classNamePrefix="react-select"
          isDisabled={disabled}
          disabled={disabled}
          isClearable={isClearable}
          styles={fieldStyles(primaryColor)}
          placeholder={placeholder}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          menuShouldBlockScroll={menuShouldBlockScroll}
          formatOptionLabel={formatOptionLabel}
        />
        {touched && error && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
    </Grid>
  );
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuPosition: PropTypes.string,
  menuPlacement: PropTypes.string,
  menuShouldBlockScroll: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isClearable: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onMenuCustomButtonClick: PropTypes.func,
  menuCustomButtonLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
  isOpen: PropTypes.bool,
  classes: PropTypes.string,
  depenpencyArray: PropTypes.array,
  onKeyDown: PropTypes.any
};

FormikSelect.defaultProps = {
  isOpen: undefined,
  label: '',
  options: [],
  placeholder: '',
  disabled: false,
  menuPosition: 'absolute',
  menuPlacement: 'bottom',
  menuShouldBlockScroll: false,
  isGrouped: false,
  isClearable: false,
  formatOptionLabel: null,
  onChange: null,
  onBlur: null,
  onMenuCustomButtonClick: null,
  menuCustomButtonLabel: 'Add New',
  isRequired: false,
  isRow: false,
  classes: '',
  depenpencyArray: [],
  onKeyDown: () => {}
};

export default FormikSelect;
