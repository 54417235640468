import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetched: null,
};

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    getItemData: (state, action) => ({
      ...state,
      fetched: action.payload,
    }),
    removeItemData: state => ({
      ...state,
      fetched: null
    })
  },
});

export const { getItemData, removeItemData } = itemSlice.actions;
export default itemSlice.reducer;
