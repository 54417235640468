import { configureStore } from '@reduxjs/toolkit';
import { serviceMiddlewares, serviceReducers } from 'services';
import authSlice from './slices/authSlice';
import sidebarSlice from './slices/sidebarSlice';
import bankingSlice from './slices/bankingSlice';
import reportsSlice from './slices/reportsSlice';
import salesSuppliersAccountSlice from './slices/salesSuppliersAccountSlice';
import userFilesSlice from './slices/userFilesSlice';
import itemSlice from './slices/itemSlice';
import customerSlice from './slices/customerSlice';
import accountSlice from './slices/accountSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    sidebar: sidebarSlice,
    banking: bankingSlice,
    reports: reportsSlice,
    salesSuppliersAccounts: salesSuppliersAccountSlice,
    userFiles: userFilesSlice,
    item: itemSlice,
    customer: customerSlice,
    account: accountSlice,
    ...serviceReducers,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serviceMiddlewares),
});

export default store;
