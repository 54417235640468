import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

// HOOKS & API & CONTEXT
import {
  useGetAttendanceCheckInQuery,
  useGetForgetCheckOutQuery,
  useUpdateForgetCheckinStatusMutation,
  useUpdateForgetToCheckOutAttendanceMutation,
} from 'services/private/attendance';
import { useGetShiftsSettingQuery } from 'services/private/shifts';
import { AttendanceContext } from 'context/AttendanceContext';

// STYLES
import { attendanceModalStyle } from 'styles/mui/containers/layoutStyles';

// COMPONENTS & UTILS
import Clock from 'containers/common/components/Clock';
import CommonModal from 'containers/common/components/CommonModal';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import useAuthInfo from 'customHooks/useAuthInfo';
import AttendanceCards from './AttendanceCards';
import CheckInOutForm from './forms/CheckInOutForm';
import AttendanceActions from './AttendanceActions';

function AttendanceBar() {
  const userProfile = useSelector(state => state.auth.user.profile);
  const [checkInStatus, setCheckInStatus] = useState(false);
  const [breakStatus, setBreakStatus] = useState(false);
  const [checkOutFormOpen, setCheckOutFormOpen] = useState(false);
  const [checkInFormOpen, setCheckInFormOpen] = useState(false);
  const [dailyReportFormOpen, setDailyReportFormOpen] = useState(false);

  const { company } = useAuthInfo();

  const { data: checkInData, isLoading: checkInDataLoading } =
    useGetAttendanceCheckInQuery({ company: company?.id });
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery({ company: company?.id });
  const { data: shiftData, isLoading: shiftLoading } = useGetShiftsSettingQuery(
    userProfile?.employee_shift,
    {
      skip: !userProfile?.employee_shift,
    }
  );

  const [updateForgetToCheckOut, { error: checkOutError, isSuccess: checkOutSuccess }] =
    useUpdateForgetToCheckOutAttendanceMutation();

  const [
    updateForgetToCheckIn,
    { error: ForgetToCheckInError, isSuccess: ForgetToCheckInSuccess },
  ] = useUpdateForgetCheckinStatusMutation();

  useHandleApiResponse(
    ForgetToCheckInError,
    ForgetToCheckInSuccess,
    'Check-In Successfully!',
    '',
    false
  );

  useHandleApiResponse(
    checkOutError,
    checkOutSuccess,
    'CheckOut successfully!',
    '',
    false
  );

  const toggleCheckOutForm = () => setCheckOutFormOpen(state => !state);
  const toggleCheckInForm = () => setCheckInFormOpen(state => !state);
  const toggleDailyReportFormOpen = () => setDailyReportFormOpen(state => !state);

  // EFFECTS
  useEffect(() => {
    if (ForgetCheckOut && !ForgetCheckOut?.report_submitted) {
      toggleDailyReportFormOpen();
    }
    if (ForgetCheckOut && !ForgetCheckOut?.check_out && ForgetCheckOut?.report_submitted) {
      toggleCheckOutForm();
    }
  }, [ForgetCheckOut]);

  const contextValues = useMemo(
    () => ({
      shiftData,
      checkInData,
      checkInStatus,
      breakStatus,
      setCheckInStatus,
      setBreakStatus,
      toggleCheckInForm,
      toggleDailyReportFormOpen,
      dailyReportFormOpen,
      checkInDataLoading,
      shiftLoading,
    }),
    [shiftData, checkInData, breakStatus, checkInDataLoading, dailyReportFormOpen, shiftLoading]
  );

  return (
    <>
      <Box className="mb-2 d-flex flex-column ">
        <Stack
          direction="row"
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Stack direction="column" flexWrap="wrap">
            <Typography fontSize={36} fontWeight={500}>
              Good Day! {userProfile.employee_name}
            </Typography>
            {userProfile?.job_title && (
            <Typography fontWeight={600} variant="body1">
              ({userProfile.job_title})
            </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="16px"
            display="flex"
            alignItems="center"
          >
            <AttendanceContext.Provider value={contextValues}>
              <AttendanceActions />
            </AttendanceContext.Provider>

            <Card sx={{ width: '200px', textAlign: 'center' }} className="p-3">
              <Clock />
              <Typography fontWeight={400} ml={0.4} fontSize={16}>
                {moment().format('Do MMMM, yyyy')}
              </Typography>
            </Card>
          </Stack>
        </Stack>

        {/* Forget to Checkout Form */}
        <CommonModal
          isOpen={checkOutFormOpen}
          toggle={() => {}}
          title="Forgot to Check Out"
        >
          <Box sx={attendanceModalStyle}>
            <CheckInOutForm
              afterRequestSend={toggleCheckOutForm}
              toggleForm={toggleCheckOutForm}
              updateForgetToCheckOut={updateForgetToCheckOut}
              attendanceID={checkInData?.id}
              reportStatus={checkInData?.is_report_submitted}
            />
          </Box>
        </CommonModal>

        {/* Forget to Checkin Form */}
        <CommonModal
          isOpen={checkInFormOpen}
          toggle={toggleCheckInForm}
          title="Forgot to Check In"
        >
          <Box sx={attendanceModalStyle}>
            <CheckInOutForm
              afterRequestSend={toggleCheckInForm}
              updateForgetToCheckIn={updateForgetToCheckIn}
              toggleForm={toggleCheckInForm}
              isCheckIn
            />
          </Box>
        </CommonModal>
      </Box>
      <AttendanceContext.Provider value={contextValues}>
        <AttendanceCards />
      </AttendanceContext.Provider>
    </>
  );
}

export default AttendanceBar;
