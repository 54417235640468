import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
// import moment from 'moment-timezone';
import { AccessTime } from '@mui/icons-material';
import moment from 'moment';
import CommonAttendanceCard from './CommonAttendanceCard';

function CheckInSince({ time, checkout }) {
  const [startTime, setStartTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    if (time && !checkout) {
      // const time = moment(checkInData?.total_hours || '0:0:0', 'HH:mm:ss');

      // const [hours, minutes, seconds] = time.split(':');
      // const time = moment(checkInData?.total_hours || '0:0:0', 'HH:mm:ss');

      // const startDate = moment(date)
      //   .set({ hours, minutes, seconds })
      //   .tz('Africa/Cairo');
      // // const egyptTime = startDate.tz('Africa/Cairo');
      setStartTime(time);
    }
  }, [time]);
  function refreshClock() {
    setTimeDiff(moment(time || '0:0:0', 'HH:mm:ss'));
  }

  useEffect(() => {
    let timerId;
    if (startTime && !checkout) {
      timerId = setInterval(refreshClock, 1000);
    }
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [startTime]);
  return (
    <CommonAttendanceCard
      title="Checked in Since"
      icon={<AccessTime color="primary" />}
      data={
        timeDiff && !checkout && startTime
          ? `${timeDiff.hours()}h  ${timeDiff.minutes()}m`
          : '0h  0m'
      }
    />
  );
}

CheckInSince.propTypes = {
  time: propTypes.string,
  // date: propTypes.string,
  checkout: propTypes.bool,
};

CheckInSince.defaultProps = {
  time: '',
  // date: '',
  checkout: true,
};

export default CheckInSince;
