/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope, react/jsx-filename-extension */
import { ACCOUNTING_CONTROLLER, GLOBAL_ADMINISTRAROR, HR_CONTROLLER, SUPER_USER } from 'utilities/constants';

import {
  Dashboard,
  Groups,
  Storage,
  Delete,
  LocalActivity,
  Assessment,
  Settings,
  Diversity2,
  AssignmentOutlined,
  ListAlt,
  ChecklistRounded,
} from '@mui/icons-material';

export const searchOptions = [
  { value: 'accounting/items', label: 'Item Master', },
  { value: 'accounting/banking', label: 'Bank Master', },
  { value: 'accounting/sales/accounts', label: 'Account Master' },
  { value: 'accounting/sales/customers', label: 'Customer' },
  { value: 'accounting/sales/quotations', label: 'Quotation' },
  { value: 'accounting/sales/pro-invoices', label: 'Proforma Invoice' },
  { value: 'accounting/sales/invoices', label: 'Sales Invoice' },
  { value: 'accounting/sales/payments-received', label: 'Receipt Voucher' },
  { value: 'accounting/sales/credit-notes', label: 'Sales Credit Note' },
  { value: 'accounting/purchases/suppliers', label: 'Supplier Master' },
  { value: 'accounting/purchases/expenses', label: 'Expense' },
  { value: 'accounting/purchases/purchase-orders', label: 'Purchase Order' },
  { value: 'accounting/purchases/bills', label: 'Purchase Invoice' },
  { value: 'accounting/purchases/payments-made', label: 'Payment Voucher' },
  { value: 'accounting/purchases/debit-notes', label: 'Purchase Debit Notes' },
  { value: 'accounting/accountant/journals', label: 'Journal Voucher' },
];

export const DashboardItem = {
  title: 'Dashboard',
  path: '/',
  icon: <Dashboard />,
};

export const Attendance = {
  title: 'Time Trace',
  icon: <ListAlt />,
  path: '/attendance',
};
export const DailyTask = {
  title: 'Daily Task',
  icon: <ChecklistRounded />,
  path: '/attendance/daily-tasks-report',
};
export const financeHrLinks = {
  title: 'HR',
  icon: <Diversity2 />,
  data: [
    {
      title: 'Salaries',
      path: '/hr/salaries',
    },
    {
      title: 'Leaves',
      path: '/hr/leaves',
    },
  ],
};

export const Reports = {
  title: 'Reports',
  path: '/reports',
  icon: <Assessment />,
};
// export const SettingsItem = {
//   title: 'Settings',
//   icon: <Settings />,
//   path: '/settings',
// };
export const InternalMeetings = {
  title: 'Meetings',
  icon: <Groups />,
  path: '/manageInternalMeetings',
};

export const bookingModuleLinks = [
  {
    title: 'Search',
    path: '/booking',
    permissions: [],
  },
  {
    title: 'Booking Queues',
    path: '/booking/queues',
    permissions: [],
  },
];

export const generalListData = [
  {
    path: '/accounting/items/add',
    title: 'Item',
  },
  {
    path: '/accounting/accountant/journals/add',
    title: 'Journal Entry',
  },
  {
    path: '/accounting/banking/add',
    title: 'Bank Account',
  },
];

export const salesListData = [
  {
    path: '/accounting/sales/accounts/add',
    title: 'Account',
  },
  {
    path: '/accounting/sales/customers/add',
    title: 'Customer',
  },
  {
    path: '/accounting/sales/quotations/add',
    title: 'Quotation',
  },
  {
    path: '/accounting/sales/pro-invoices/add',
    title: 'Proforma Invoice',
  },
  {
    path: '/accounting/sales/invoices/add',
    title: 'Invoice',
  },
  {
    path: '/accounting/sales/payments-received/add',
    title: 'Receipt Voucher',
  },
  {
    path: '/accounting/sales/credit-notes/add',
    title: 'Credit Note',
  },
];

export const purchasesListData = [
  {
    path: '/accounting/purchases/suppliers/add',
    title: 'Supplier',
  },
  {
    path: '/accounting/purchases/expenses/add',
    title: 'Expense',
  },
  {
    path: '/accounting/purchases/purchase-orders/add',
    title: 'Purchase Order',
  },
  {
    path: '/accounting/purchases/bills/add',
    title: 'Bill',
  },
  {
    path: '/accounting/purchases/payments-made/add',
    title: 'Payment Voucher',
  },
  {
    path: '/accounting/purchases/debit-notes/add',
    title: 'Debit Note',
  },
];

export const notifications = [
  {
    name: 'Cristopher Changer',
    message: ' has started a new project',
    date: '09:02',
  },
  {
    name: 'Sveta Narry',
    message: ' has closed a project',
    date: '09:00',
  },
  {
    name: 'Lory McQueen',
    message: ' has started a new project as a Project Managert',
    date: '08:43',
  },
  {
    name: 'Cristopher Changer',
    message: ' has closed a project',
    date: '08:43',
  },
];

export const topbarSearchInitialValues = {
  module: '',
  query: ''
};

export const topbarSearchModifiedValues = [
  {
    label: 'Module',
    class: 12,
    fieldName: 'module',
    placeholder: 'Select Module',
    type: 'list',
    options: searchOptions,
    value: null,
  },

  {
    label: 'Search',
    class: 12,
    placeholder: 'Search...',
    fieldName: 'query',
    type: 'text',
    value: '',
  },
];
