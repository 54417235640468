import { privateAPI } from './index';

export const companyProfileAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getCompanyList: build.query({
      query: params => ({
        url: 'company-detail/',
        method: 'GET',
        params,
      }),
      providesTags: ['comapnyList']
    }),
    getAllCompanyList: build.query({
      query: params => ({
        url: 'company-detail/all_companies/',
        method: 'GET',
        params,
      }),
      providesTags: ['comapnyList']
    }),
    getCompanyDetail: build.query({
      query: id => ({
        url: `company-detail/${id}/`,
        method: 'GET',
      }),
      providesTags: ['companyDetail']
    }),
    addCompany: build.mutation({
      query: values => {
        const formData = new FormData();

        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: 'company-detail/',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['comapnyList']
    }),
    editCompany: build.mutation({
      query: ({ values, id }) => {
        const formData = new FormData();

        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: `company-detail/${id}/`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: ['comapnyList', 'companyDetail']
    }),
    deleteCompany: build.mutation({
      query: id => ({
        url: `company-detail/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['comapnyList']
    }),
  }),
});

export const { useLazyGetCompanyHolidaysQuery,
  useGetCompanyListQuery,
  useGetAllCompanyListQuery,
  useLazyGetCompanyListQuery,
  useDeleteCompanyMutation,
  useGetCompanyDetailQuery,
  useAddCompanyMutation,
  useEditCompanyMutation,
} = companyProfileAPI;
