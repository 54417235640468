import moment from 'moment';
import { DATE_FORMAT_PRINT } from 'utilities/constants';
import { privateAPI } from './index';

export const authAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    logout: build.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),
    registerEmployee: build.mutation({
      query: ({ payload }) => {
        const values = payload;

        const updatedValues = {
          profile: {
            company: values.company,
            primary_company: values.primary_company,
            employee_name: values.employee_name,
            gender: values.gender,
            dob: moment(values.dob).format(DATE_FORMAT_PRINT),
            age: values.age,
            marital_status: values.marital_status,
            passport_num: values.passport_num,
            passport_expiration_date: moment(
              values?.passport_expiration_date || ''
            ).format(DATE_FORMAT_PRINT),
            visa_num: values.visa_num,
            visa_expiration_date: moment(values.visa_expiration_date || '').format(
              DATE_FORMAT_PRINT
            ),
            eid_num: values.eid_num,
            eid_expiration_date: moment(values?.eid_expiration_date || '').format(
              DATE_FORMAT_PRINT
            ),
            nationality: values.nationality,
            personal_mob: values.personal_mob,
            personal_email: values.personal_email,
            address: values.address,
            bank_account: values.bank_account,
            account_name: values.account_name,
            bank_name: values.bank_name,
            branch_address: values.branch_address,
            iban: values.iban,
            currency: values.currency,
            swift_code: values.swift_code,
            job_title: values.job_title,
            company_mob: values.company_mob,
            company_email: values.company_email,
            salary_basic: values.salary_basic,
            salary_allowance: values.salary_allowance,
            gross_salary: values.gross_salary,
            start_date: values.start_date,
            employee_shift: values.employee_shift,
            medical_insurance_num: values.medical_insurance_num,
            medical_insurance_expiration_date: moment(
              values?.medical_insurance_expiration_date || ''
            ).format(DATE_FORMAT_PRINT),
            contract_type: values.contract_type,
            notes: values.notes,
            dependent: values.dependent,
            profile_pic: values?.profile_pic,
            department: values.user_department,
            user_department: values.user_department,

            imm_super_id: values.imm_super_id,
            confirm_password: values.confirm_password,
            // system_access: selectedCompany?.label === COMPANY_NAME,
            status: 'active',
            role: values.role,

          },
          has_any_child_in_uae: values.has_any_child_in_uae ?? false,
          user_groups: values.user_groups,
          email: values.company_email,
          password: values.password,
        };

        const formData = new FormData();

        Object.entries(updatedValues?.profile)?.forEach(([key, value]) => {
          if (value) {
            formData.append(`profile.${key}`, value);
          }
        });

        formData.append('has_any_child_in_uae', values.has_any_child_in_uae ?? false);
        formData.append('user_groups', values.user_groups);
        formData.append('email', values.company_email);
        formData.append('password', values.password);

        return {
          url: '/auth/register',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['employeesList'],
    }),
    companyBaseInfo: build.query({
      // query: () => '/company/info',
      query: () => 'user/info/company',
      providesTags: ['companyBaseInfo'],
    }),
    companyDetailInfo: build.query({
      query: () => '/company/info',
      providesTags: ['companyInfo'],
      // query: () => 'user/info/company',
    }),
    loadUser: build.query({
      query: () => 'auth/user',
      providesTags: ['loadUser']
    }),
    getAllEmployees: build.query({
      query: params => ({
        url: '/users/search/status/all',
        method: 'GET',
        params
      }),
      providesTags: ['employeesList'],
    }),
    getCompanyProfiles: build.query({
      query: params => ({
        url: 'CompanyProfile/',
        params }),
      invalidatesTags: ['employeesList'],
    }),
  }),
});

export const {
  useLogoutMutation,
  useCompanyBaseInfoQuery,
  useCompanyDetailInfoQuery,
  useLoadUserQuery,
  useLazyLoadUserQuery,
  useRegisterEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetCompanyProfilesQuery,
} = authAPI;
