import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetched: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    getAccountData: (state, action) => ({
      ...state,
      fetched: action.payload,
    }),
    removeAccountData: state => ({
      ...state,
      fetched: null
    })
  },
});

export const { getAccountData, removeAccountData } = accountSlice.actions;
export default accountSlice.reducer;
