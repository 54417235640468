import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from 'styles/mui/theme';
import store from 'store';
import { Provider, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { userLoaded } from 'store/slices/authSlice';
import { useLoadUserQuery } from 'services/private/auth';
import useScrollToTop from 'customHooks/useScrollToTop';
// import { ErrorBoundary } from 'react-error-boundary';
// import Fallback from 'containers/common/components/SomethingWentWrongUi';
import useAuthInfo from 'customHooks/useAuthInfo';
import Router from './routes';

function App() {
  const [modifiedTheme, setModifiedTheme] = useState(theme);

  const dispatch = useDispatch();
  const { data: loadUserData } = useLoadUserQuery();

  // eslint-disable-next-line no-unused-vars
  const { company } = useAuthInfo();

  useScrollToTop();

  useEffect(() => {
    if (loadUserData) {
      dispatch(userLoaded(loadUserData));
    }
  }, [loadUserData]);

  useEffect(() => {
    if (company?.color_code) {
      setModifiedTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            main: company.color_code
          },
          chartsPlatte: {
            ...theme.palette.chartsPlatte,
            main: company.color_code
          }
        } });
      document.documentElement.style.setProperty('--primaryColor', company.color_code);
    }
  }, [company]);

  return (

    <ThemeProvider theme={modifiedTheme}>
      <Provider store={store}>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {/* <ErrorBoundary FallbackComponent={Fallback}> */}
          <Router />
          {/* </ErrorBoundary> */}
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>

  );
}

export default App;
