import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { useGetCompanyListQuery, useGetAllCompanyListQuery } from 'services/private/companyProfile';
import { Apartment } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getCompanyDetail } from 'store/slices/authSlice';
import useAuthInfo from 'customHooks/useAuthInfo';
import { useNavigate } from 'react-router-dom';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function CompanyMenu() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(null);

  const dispatch = useDispatch();
  const primaryColor = useGetThemeColor();

  const { company, isAuthenticated, profile } = useAuthInfo();
  const { data: companies = [], refetch } = profile?.is_default_user ? useGetAllCompanyListQuery() : useGetCompanyListQuery();

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated,]);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCompany = pro => {
    dispatch(getCompanyDetail(pro));
    // window.location.reload();
    navigate('/');
  };

  return (
    <>
      <Tooltip title="Select Company" placement="bottom">
        <IconButton onClick={handleMenuClick} sx={{ maxHeight: '40px' }}>
          <Apartment />
        </IconButton>
      </Tooltip>

      <Menu
        key={isOpen}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={handleClose}
        sx={{
          top: 40,
          left: '-8rem',
          height: '350px',
          overflow: 'auto',
          '& .Mui-selected, & li:hover': {
            backgroundColor: `${primaryColor} !important`,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {companies?.results?.map(profiles => (
          <MenuItem
            key={profiles.id}
            selected={profiles.id === company?.id}
            onClick={() => { handleClose(); handleSelectCompany(profiles); }}
          >

            <Typography variant="body2">{profiles.company_name}</Typography>

          </MenuItem>
        ))}

        {company?.results?.length === 0 && (
          <MenuItem>
            <Typography variant="body2">No Company Found</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default CompanyMenu;
