import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import moment from 'moment';

// CONTEXT
import { useAttendanceContext } from 'context/AttendanceContext';

// COMPONENTS & UTILS
import { allTagsStatuses } from '../utilities/data';
import CommonAttendanceCard from './cards/CommonAttendanceCard';
import BreakCard from './cards/BreakCard';
import CheckInSince from './cards/CheckInSince';

function AttendanceCards() {
  const { shiftData, checkInData = {}, breakStatus, checkInDataLoading, shiftLoading } =
    useAttendanceContext();

  const { check_in_time: checkInTime, is_check_out: isCheckOut, status } = checkInData;

  const formattedShift = useMemo(
    () => moment(shiftData?.start_time, ['HH:mm:ss']).format('hh:mm A'),
    [shiftData?.start_time]
  );

  const formattedCheckIn = useMemo(() => {
    if (checkInTime && !isCheckOut) return moment(checkInTime, ['HH:mm:ss']).format('hh:mm:ss A');

    return '00:00:00';
  }, [checkInTime, isCheckOut]);

  return (
    <Box sx={{ mt: '15px', mb: '0px' }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3.5 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4} sm={4} md={3}>
          <CommonAttendanceCard
            title="Office Start Time"
            icon={<AccessTime color="primary" />}
            data={shiftLoading ? '00:00:00' : formattedShift}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <CommonAttendanceCard
            title={`Check-In Time ${status && !isCheckOut ? `(${allTagsStatuses[status]})` : ''}`}
            icon={<AccessTime color="primary" />}
            data={shiftLoading ? '00:00:00' : formattedCheckIn}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <BreakCard
            breakData={checkInData?.break_time}
            checkInData={checkInData}
            status={breakStatus}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={3}>
          <CheckInSince
            date={checkInData?.date}
            time={moment(checkInData?.total_hours || '0:0:0', 'HH:mm:ss')}
            checkout={checkInData?.is_check_out}
            loading={checkInDataLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AttendanceCards;
