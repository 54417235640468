import { Box, Typography } from '@mui/material';
import { capitalize, replace, } from 'lodash';
import { ToWords } from 'to-words';
import * as DOMPurify from 'dompurify';
import moment from 'moment';
import { greyText } from 'styles/mui/themeVariables';
import { CHANGE_DEFAULT_MODE, ROWS_PER_PAGE } from './constants';

export const splitLongText = value => (value ? value.split(' ').slice(0, 5).join(' ').concat('...') : '');

export const changeTabFavIcon = (companyInfo = {}) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = companyInfo.fav_icon;
};
export const changeDefaultMode = modifiedPaymentModes => ({
  type: CHANGE_DEFAULT_MODE,
  payload: modifiedPaymentModes,
});
export const toTitleCase = str => str
  .split('_') // Split the string by underscores
  .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
  .join(' '); // Join the words with a space

export function formatAmount(amount = 0) {
  try {
    const formattedAmount = amount.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (formattedAmount) return formattedAmount;
    return amount;
  } catch (error) {
    return '0.00';
  }
}

export function formatNumber(number = 0) {
  const convertedNum = Number(number).toFixed(2);
  return Number(convertedNum);
}

export const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: false,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
  },
});

export const handleGetAmountInWords = amount => {
  const amountToWords = toWords.convert(amount)?.toLowerCase();
  const capitalLetter = amountToWords.charAt(0).toUpperCase();
  if (amountToWords?.includes('point')) {
    return `${capitalLetter}${amountToWords
      ?.replace('point', 'and')
      ?.slice(1, amountToWords.length)} fills`;
  }
  return `${capitalLetter}${amountToWords?.slice(1, amountToWords.length)}`;
};

export function formatString(name) {
  let separatedName = [];
  if (name && name.indexOf('_') > -1) separatedName = name.split('_');
  if (name && name.indexOf('-') > -1) separatedName = name.split('-');
  if (name && name.indexOf(' ') > -1) separatedName = name.split(' ');

  if (separatedName.length > 0) {
    const newName = separatedName.map(item => capitalize(item));
    const formattedName = newName.join(' ');
    return formattedName;
  }
  return capitalize(name);
}

export const formatAmountInShortForm = n => {
  if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(2)}K`;
  if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(2)}M`;
  if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)}B`;
  if (n >= 1e12) return `${(n / 1e12).toFixed(2)}T`;
  return n;
};

export const abbreviateAmount = amount => {
  if (amount < 0) {
    return `-${formatAmountInShortForm(-1 * amount)}`;
  }
  return formatAmountInShortForm(amount);
};

export function getSorting(order, orderBy) {
  if (orderBy === 'created_at') {
    if (order === 'desc') {
      return (a, b) => {
        if (new Date(a[orderBy]) < new Date(b[orderBy])) {
          return -1;
        }
        if (new Date(a[orderBy]) > new Date(b[orderBy])) {
          return 1;
        }
        return 0;
      };
    }
    return (a, b) => {
      if (new Date(a[orderBy]) > new Date(b[orderBy])) {
        return -1;
      }
      if (new Date(a[orderBy]) < new Date(b[orderBy])) {
        return 1;
      }
      return 0;
    };
  }

  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export const getSpaces = value => {
  let spaces = '';
  for (let i = 0; i <= value * 5; i += 1) {
    spaces += ' ';
  }
  return spaces;
};
export function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  return [];
}

// General
export const getRowsPerPage = search => {
  const query = {};
  const limit = search.get('limit') || '';
  const offset = search.get('offset') || '';
  if (limit) {
    query.limit = limit;
  } else {
    query.limit = ROWS_PER_PAGE;
  }
  if (offset && offset !== '0') {
    query.offset = offset;
  } else {
    query.offset = 0;
  }
  return query;
};
export const getsearchQueryOffsetAndLimitParams = location => {
  let query = {};
  const search = new URLSearchParams(location.search);
  if (!search) return query;
  const limit = getRowsPerPage(search);
  query = { ...limit };
  return query;
};
export function getSearchParamsList(searchParams) {
  return Object.fromEntries(searchParams);
}

export function getLinkAddress(type, id, isSale = false) {
  let link = '';

  if (type === 'Invoice') link = `/accounting/sales/invoices/${id}/detail`;
  else if (
    type === 'Invoice Payment' ||
    type === 'Receipt Voucher' ||
    type === 'Customer Receipt' ||
    ((type === 'Excess Payment' || type === 'Payment Received') && isSale)
  ) link = `/accounting/sales/payments-received/${id}/detail`;
  else if (type === 'Credit Note') link = `/accounting/sales/credit-notes/${id}/detail`;
  else if (type === 'Bill') link = `/accounting/purchases/bills/${id}/detail`;
  else if (
    type === 'Bill Payment' ||
    type === 'Payment Voucher' ||
    type === 'Supplier Payment' ||
    (type === 'Excess Payment' && !isSale)
  ) link = `/accounting/purchases/payments-made/${id}/detail`;
  else if (type === 'Debit Note') link = `/accounting/purchases/debit-notes/${id}/detail`;
  else if (type === 'Expense' || type === 'Expense Paid') link = `/accounting/purchases/expenses/${id}/detail`;
  else if (type === 'Journal Voucher') link = `/pages/accounting/accountant/journals/${id}/detail`;
  else if (type === 'Account') link = `/accounting/sales/accounts/${id}/detail?type=sales`;
  else if (type === 'Customer') link = `/accounting/sales/customers/${id}/detail`;
  else if (type === 'Quotation') link = `/accounting/sales/quotations/${id}/detail`;
  else if (type === 'Pro Invoice') link = `/accounting/sales/pro-invoices/${id}/detail`;
  else if (type === 'Supplier') link = `/accounting/purchases/suppliers/${id}/detail?type=purchases`;
  else if (type === 'Purchase Order') link = `/accounting/purchases/purchase-orders/${id}/detail`;

  return link;
}

export const changeQueryParameters = (query, queryObject) => {
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value) {
      query.set(key, value);
    } else {
      query.delete(key);
    }
  });
  return query.toString();
};

export const deleteQueryParameter = (query, queryKey) => {
  query.delete(queryKey);
  return query.toString();
};

export const getPaginationData = location => {
  const query = new URLSearchParams(location.search);
  const rowsPerPage = query.get('rows_per_page') || '20';
  const page = query.get('page') || '0';

  return { rowsPerPage: +rowsPerPage, page: +page, query };
};

export function getAccountOptions(accounts = [], disbledOptionTitles = []) {
  const groups = new Set(accounts?.map(item => item.account_type.account_type_formatted));

  const typeOptions = [...groups].map(group => ({
    label: group,
    options: accounts
      ?.filter(type => type.account_type.account_type_formatted === group)
      ?.map(item => {
        const option = {
          label: item.account_name,
          value: `${item.id}`,
          isDisabled: disbledOptionTitles.includes(item.account_name),
        };
        return option;
      }),
  }));

  return typeOptions;
}

export const convertURLToFile = async url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  const response = await fetch(`${url}?timestamp=${new Date().getTime()}`);
  const blob = await response.blob();
  const file = new File([blob], `${fileName}`, {
    type: blob.type,
  });
  return file;
};

export async function urlToFileBase64(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    // console.error('Error:', error);
    return null;
  }
}

export function isValidURL(value) {
  const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(:\d+)?)(\/[^\s]*)?$/i;
  return urlPattern.test(value);
}

export const getPaymentModesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(mode => ({
    value: mode.payment_mode_name,
    label: mode.payment_mode_name,
  }))
  : []);

export const getBankAccountsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(account => ({
    value: account.id,
    label: `${account.bank_name} - ${account.currency}`,
  }))
  : []);

export const getCurrenciesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.currency_code,
    label: `${item.currency_code} - ${item.currency_name}`,
  }))
  : []);

export const getCurrenciesOptionsWithId = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.id,
    label: `${item.currency_code} - ${item.currency_name}`,
  }))
  : []);

export const getSalesPersonsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: item.sales_person_name,
  }))
  : []);

export const getPaymentTermsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.term_name,
    label: item.term_name,
  }))
  : []);

export const getPlaceOfSupplyOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.place_name,
    label: item.place_name,
  }))
  : []);

export const getTaxTreatmentsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.lookup_name,
    label: item.lookup_name,
    description: item.description,
  }))
  : []);

export const getPurOrdersOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: item.pur_order_formatted_number,
  }))
  : []);

export const getTaxRatesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(rate => ({
    value: `${rate.id}`,
    label: rate.tax_rate_name,
  }))
  : []);

export const getTitleOptions = optionsArray => (optionsArray
  ? optionsArray?.map(title => ({
    value: title.lookup_name,
    label: title.lookup_name,
  }))
  : []);

export const getCountryOptions = optionsArray => (optionsArray
  ? optionsArray?.map(country => ({
    id: country.id,
    value: country.name,
    label: country.name,
  }))
  : []);
export const getStateOptions = optionsArray => (optionsArray
  ? optionsArray?.map(state => ({
    id: state.id,
    value: state.name,
    label: state.name,
  }))
  : []);
export const getCityOptions = optionsArray => (optionsArray
  ? optionsArray?.map(city => ({
    id: city.id,
    value: city.name,
    label: city.name,
  }))
  : []);
export const getInvoiceOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: `${item.invoice_num}-${item.category_name}-${item.invoice_prefix}`,
  }))
  : []);

export const getItemsOptions = optionsArray => (optionsArray
  ? optionsArray
    ?.filter(item => item.is_active)
    .map(item => ({
      value: item.item_name,
      label: item.item_name,
      // isDisabled: getOptionDisability(item, values),
    }))
  : []);

export const getSuppliersOptions = optionsArray => (optionsArray
  ? optionsArray
    ?.filter(supplier => supplier.is_active)
    ?.sort(getSorting('desc', 'supplier_name'))
    ?.map(supplier => ({
      value: `${supplier.id}`,
      label: supplier.supplier_name,
    }))
  : []);

export const getCompaniesProfilesOptions = optionsArray => (optionsArray
  ? optionsArray.map(company => ({
    value: `${company?.id}`,
    label: company?.company_name,
  }))
  : []);

export const getPaymentModeOptions = optionsArray => (optionsArray
  ? optionsArray?.map(mode => ({
    value: mode.payment_mode_name,
    label: mode.payment_mode_name,
  }))
  : []);

export const getSalesAccountsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(salesAccount => ({
    value: `${salesAccount.id}`,
    label: salesAccount.sales_account_name,
  }))
  : []);

export const getCustomersOptions = optionsArray => {
  if (optionsArray?.length) {
    return [...optionsArray].sort(getSorting('desc', 'company_name')).map(account => ({
      value: `${account.id}`,
      label: account.company_name,
    }));
  }

  return [];
};

export const fileUrlWithTimeStamp = (url = '') => `${url}?timestamp=${new Date().getTime()}`;

export const downloadFileWithURL = (fileURL, fileName = 'document') => {
  fetch(fileUrlWithTimeStamp(fileURL))
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      link.click();
      URL.revokeObjectURL(url); // Clean up the URL object to free memory
    })
    .catch(() => {
      // Handle error if the fetch request fails
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    });
};

export function getEmailTemplate({
  salutation,
  message,
  heading,
  details,
  currencySymbol,
}) {
  return `
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Email Template</title>
      </head>
      <body style="margin: 0; padding: 10px 20px;">
        <div 
        style=" max-width: 500px; margin: 20px auto; background-color: #f4f4ff; padding: 30px; border-radius: 8px; box-shadow: 0px 0px 10px 0px darkgrey;font-family: Arial, Helvetica, sans-serif">
          <div style="display: flex; width: 100%;">
            <div style="border-bottom: 5px solid #dfc369; width: 40%;"></div>
              <div style="width: 20%; padding: 0 10px;">
                <img style="width: 100%;" src="https://crm-application-storages.s3.ap-south-1.amazonaws.com/media/logo/Logo-bg.png" alt="logo" />
              </div>
            <div style="border-bottom: 5px solid #dfc369; width: 40%;"></div>
          </div>
          <div style="display: flex;flex-direction: column; margin: 30px 0 10px 0;">
            <div style="text-align: justify;">
              <p>Dear <span style="font-weight: 600;">${salutation}</span>,</p>
              <p>${message}</p>
             </div>
          </div>
          ${
  heading && details.length > 0
    ? `
                  <div style="background-color: black; padding: 10px 0; width: 92%; margin: 0 auto;">
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                    <table style="width:92%; margin: 0 auto; border-collapse: collapse;" >
                      <tbody >
                        <tr >
                          <td>
                            <h5 style="color:#dfc369; padding:10px; margin: 0; font-size: 18px;">
                              ${heading.documentName}
                            </h5>
                          </td>
                          <td>
                            <h5 style="color:#dfc369; padding:10px; margin: 0; font-size: 18px; text-align: right;">
                              ${heading.number}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                    <table style="width:92%; margin: 0 auto; border-collapse: collapse;" >
                      <tbody >
                        ${details
    .map(
      item => `<tr>${
        item.value !== false && item.value !== null
          ? `<td style="padding: 10px 0;">
                                <p style="margin: 0;color: white; padding-left: 10px; text-align: left;">${item.key}</p>
                              </td>
                              <td style="padding: 10px 0;">
                                  <p class="price" style="margin: 0;text-align: right; padding-right: 10px; ">
                                      <span style="color: #dfc369;">${currencySymbol}</span>
                                      <span style="color: white">${item.value}</span>
                                  </p>
                              </td>`
          : ''
      }
                            </tr>`
    )
    .join('')}
                        </tbody>
                    </table>
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                  </div>
                `
    : ''
}
        <div style="margin-top: 20px;">
          <p style="line-height: 30px;">
            Regards,
            <br />
            Luxury Events and VIP Travel DMCC
          </p>
        </div>
      </div>
    </body>
  </html>
    `;
}

export const groupArrayOfObjects = (templist, key) => templist.reduce((rv, x) => {
  // eslint-disable-next-line no-param-reassign
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

/* eslint-disable */
export const formatTaxTreatmentOptions = (option, metaData) =>
  metaData.context === 'menu' ? (
    <div>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {option.label}
      </Typography>
      <Typography variant="caption">{option.description}</Typography>
    </div>
  ) : (
    option.label
  );

export function getTaxSummaryData(items) {
  return items.reduce((acc, val) => {
    const existingData = acc.find(item => item.vat_rate === val.vat_rate);
    if (existingData) {
      const index = acc.findIndex(item => item.id === existingData.id);
      const newData = {
        id: existingData.id,
        vat_rate: existingData.vat_rate,
        vat_rate_name: existingData.vat_rate_name,
        gross_amount: val.amount_ex_vat + existingData.gross_amount,
        vat_amount: val.vat_amount + existingData.vat_amount,
      };
      acc.splice(index, 1, newData);
    } else {
      acc.push({
        id: val.id,
        vat_rate: val.vat_rate,
        vat_rate_name: val.vat_rate_name,
        gross_amount: val.amount_ex_vat,
        vat_amount: val.vat_amount,
      });
    }
    return acc;
  }, []);
}

export function getFormatedDate(conferenceStartDate, conferenceEndDate) {
  const startDateOptions = { month: 'long', day: '2-digit', year: 'numeric' };
  const endDateOptions = { month: 'long', day: '2-digit', year: 'numeric' };
  const startDate = new Date(conferenceStartDate);
  const endDate = new Date(conferenceEndDate);
  if (startDate.getFullYear() === endDate.getFullYear()) {
    delete startDateOptions.year;
  }
  let formatedStartDate = startDate.toLocaleDateString('en-GB', startDateOptions);
  const formatedEndDate = endDate.toLocaleDateString('en-GB', endDateOptions);
  if (startDate.getMonth() === endDate.getMonth()) {
    formatedStartDate = startDate.getDate().toString().padStart(2, '0');
  }

  return `${formatedStartDate} - ${formatedEndDate}`;
}
export const getPaidAmount = (orderDetail, currencyType ="bcy", exchangeRate=1) => {

  let amount =     Number(fcyAmountResult(currencyType, orderDetail?.without_change_grand_total || 0, exchangeRate)) ;
  if (orderDetail?.refunded_amount) amount -=  fcyAmountResult(currencyType, orderDetail.refunded_amount, exchangeRate) || 0;
  if (orderDetail?.payment_amount) amount -= fcyAmountResult(currencyType, orderDetail.payment_amount, exchangeRate) || 0;
  if (orderDetail?.credits_used) amount -=  fcyAmountResult(currencyType, orderDetail.credits_used, exchangeRate)|| 0;
  if (orderDetail?.credit_applied) amount -= fcyAmountResult(currencyType, orderDetail.credit_applied, exchangeRate) || 0;

  return amount;
};

export function getOptionDisability(item, values) {
  let data;

  // for qu
  if (values.quotation_items) {
    data =
      values.quotation_items &&
      values.quotation_items.some(val => item.item_name === val.service_type);
  } else if (values.pur_order_items) {
    data =
      values.pur_order_items &&
      values.pur_order_items.some(val => item.item_name === val.service_type);
  } else if (values.bill_items) {
    data =
      values.bill_items &&
      values.bill_items.some(val => item.item_name === val.service_type);
  } else if (values.supplier_credit_items) {
    data =
      values.supplier_credit_items &&
      values.supplier_credit_items.some(val => item.item_name === val.service_type);
  } else if (values.credit_note_items) {
    data =
      values.credit_note_items &&
      values.credit_note_items.some(val => item.item_name === val.service_type);
  } else {
    data =
      values.invoice_items && values.invoice_items
        ? values.invoice_items.some(val => item.item_name === val.service_type)
        : values.pro_invoice_items &&
          values.pro_invoice_items.some(val => item.item_name === val.service_type);
  }
  return data;
}

export function yupTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }

  const isValid = moment(value, format, true).isValid();

  if (!isValid) {
    return false;
  }

  return true;
}

export function yupLessTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }
  const currentTime = moment().format('HH:mm:ss');
  const isValid = moment(value, format).isBefore(moment(currentTime, format));
  if (!isValid) {
    return false;
  }

  return true;
}
export const getFlattenArray = (data = [], childrenKey = 'children') => {
  return data.reduce(
    (acc, item) =>
      acc.concat(item, ...getFlattenArray(item[childrenKey] || [])),
    []
  );
};

export const sanitizeHTML = html => DOMPurify.sanitize(html);

export const formatDate = (date, format = 'YYYY-MM-DD') =>
  date ? moment(date).format(format) : null;


export const getFormattedTime = time => {
  const formattedTime = moment.duration(time, 'HH:mm:ss');
  return `${formattedTime.days() * 24 + formattedTime.hours()}h ${formattedTime.minutes()}m`
}

export const largeTextSlice = (value, limit) => {
  return ((value?.length > limit) ? `${value.slice(0, limit)}...` : value);
}

export function renderLabelData(label, value) {
  return (
    value ? (
    <Box className="d-flex gap-1">
      <Typography variant="body2">
        {label}:
      </Typography>
      <Typography variant="body2" sx={{ color: greyText }}>
        {value}
      </Typography>
    </Box>
    ):null

  );
}

export const fcyAmountResult = (state ,amount, exchangeRate = 1) => {
 const formateNumber =  replace(amount, /,/g, '');

 if(!formatNumber) return 0;

 return state === 'bcy' ? Number(formateNumber) * Number(exchangeRate) : Number(formateNumber);
}

export function findKeyWithExchangeRate(obj) {
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [key, value] of Object.entries(obj)) {
    if (key.includes('exchange_rate_')) {
      return key;
    }
  }// Return null if no key contains 'exchange_rate'

  return null;
}

export const groupItems = (item, isComplimentry='complimentry') => {
let groupedItems = [];

if(isComplimentry !== 'complimentry')
{
  groupedItems = item.reduce((agg, currentItem) => {

   if(!currentItem?.complimentary){ 
    const found = agg.find(
      rec => rec.service_type === currentItem.service_type
    );

    if (found) {
      found.items.push(currentItem);
      found.unit_price_ex_vat += parseFloat(currentItem.unit_price_ex_vat);
      found.amount_ex_vat += parseFloat(currentItem.amount_ex_vat);
      found.gross_amount += parseFloat(currentItem.gross_amount);
    } else {
      agg.push({
        service_type: currentItem.service_type,
        items: [currentItem],
        unit_price_ex_vat: parseFloat(currentItem.unit_price_ex_vat),
        amount_ex_vat: parseFloat(currentItem.amount_ex_vat),
        gross_amount: parseFloat(currentItem.gross_amount),
      });
    }

  }
    return agg;
  }, []);
}else{
  groupedItems = item.reduce((agg, currentItem) => {

    const found = agg.find(
      rec => rec.service_type === currentItem.service_type
    );

    if (found) {
      found.items.push(currentItem);
      found.unit_price_ex_vat += parseFloat(currentItem.unit_price_ex_vat);
      found.amount_ex_vat += parseFloat(currentItem.amount_ex_vat);
      found.gross_amount += parseFloat(currentItem.gross_amount);
    } else {
      agg.push({
        service_type: currentItem.service_type,
        items: [currentItem],
        unit_price_ex_vat: parseFloat(currentItem.unit_price_ex_vat),
        amount_ex_vat: parseFloat(currentItem.amount_ex_vat),
        gross_amount: parseFloat(currentItem.gross_amount),
      });
    }

    return agg;
  }, []);
}
  return groupedItems;
};

export default groupItems;

export const checkAllComplimentry = (values = [])=>  values.filter(item => item.complimentary).length === (values.length);
