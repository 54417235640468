import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery, Drawer, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { toggleSidebar } from 'store/slices/sidebarSlice';
import { sidebarCollapse, sidebarExpand } from 'styles/containers/wrapper';
import SidebarContent from './SidebarContent';

function Sidebar({ isOpen, handleToggleSidebar }) {
  const dispatch = useDispatch();
  const { collapse } = useSelector(state => state.sidebar);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box>
      {!isMobile ? (
        <Box style={!isOpen ? sidebarExpand : sidebarCollapse} className="no-print">
          <SidebarContent isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
        </Box>
      ) : (
        <Drawer
          className="no-print"
          sx={sidebarExpand}
          open={collapse}
          onClose={() => dispatch(toggleSidebar())}
        >
          <SidebarContent isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
        </Drawer>
      )}
    </Box>
  );
}

export default Sidebar;
Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};
