/* eslint-disable no-unused-vars */
import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { linkBlue } from 'styles/mui/themeVariables';
import { DEFAULT_CURRENCY } from 'utilities/constants';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function CommonMenu({ menus, selectedOption, isSimple, func, variant, isOverdue, overDueType, isPageTitle }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const primaryColor = useGetThemeColor();

  const navigate = useNavigate();

  const handleMenuClick = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(index => {
    setAnchorEl(null);
    setSelectedIndex(index);
  }, []);

  const commonMenuStyling = useMemo(() => ({
    '&.Mui-selected, &:hover': {
      backgroundColor: `${primaryColor} !important`,
    },
    '&.Mui-selected span': {
      color: 'white !important'
    }
  }), [primaryColor]);

  return (
    <>
      <Button
        onClick={handleMenuClick}
        variant={isSimple ? variant : 'pageTitle'}
        size={isSimple ? 'small' : ''}
        sx={{ color: isSimple ? '' : 'black', fontWeight: isOverdue ? 'bold' : '', marginRight: isOverdue ? '-2.5rem' : '', fontSize: isPageTitle ? '1.3rem' : null }}
      >
        {selectedOption} <KeyboardArrowDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}

      >
        {isOverdue ? (
          menus.map((menu, idx) => (
            <MenuItem
              key={`${idx}-${menu.label}-${menu.interval}`}
              selected={idx === selectedIndex}
              sx={{
                ...commonMenuStyling,
              }}
              // sx={{ backgroundColor: (idx === selectedIndex) ? primaryBg : null, color: (idx === selectedIndex) ? primaryClr : null, }}
              onClick={() => {
                func(menu);
                navigate(`/reports/${overDueType === 'receivable' ? 'ar' : 'ap'}-aging-details?duration=today&date_type=due_date&interval=${menu.interval}`);
                handleClose(idx);
              }}
              className="row"
            >
              <Typography
                variant="smallText"
                sx={{ minWidth: '200px' }}
                className="d-flex justify-content-between"
              >{menu.duration} <span style={{ color: linkBlue }}> {DEFAULT_CURRENCY}{menu.amount}</span>
              </Typography>
            </MenuItem>
          ))
        ) : (
          menus.map((menu, idx) => (
            <MenuItem
              key={`${idx}-${menu.label}`}
              sx={{
                ...commonMenuStyling,
                minWidth: isPageTitle ? '120px' : null,
                paddingLeft: isPageTitle ? '25px' : null,
                // backgroundColor: (idx === selectedIndex) ? primaryBg : null,
              }}
              selected={idx === selectedIndex}
              onClick={() => {
                func(menu);
                handleClose(idx);
              }}
            >
              {menu.label}
            </MenuItem>
          ))
        )}

      </Menu>
    </>
  );
}

CommonMenu.propTypes = {
  menus: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  isSimple: PropTypes.bool,
  func: PropTypes.func,
  variant: PropTypes.string,
  isOverdue: PropTypes.bool,
  overDueType: PropTypes.string,
  isPageTitle: PropTypes.bool,
};

CommonMenu.defaultProps = {
  isSimple: false,
  func: () => { },
  variant: 'contained',
  isOverdue: false,
  overDueType: 'receivable',
  isPageTitle: false,
};

export default CommonMenu;
