/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROWS_PER_PAGE } from 'utilities/constants';
import { getSearchParamsList } from 'utilities/helpers';
import useAuthInfo from './useAuthInfo';

function useGetParams(limit,) {
  const { company } = useAuthInfo();
  const [paramsState, setParamsState] = useState(null);
  const [searchParams] = useSearchParams();
  const paramsList = getSearchParamsList(searchParams);

  const {
    rows_per_page: rowsPerPage = limit || ROWS_PER_PAGE,
    page = 0,
    status = undefined,
    query: searchQuery = undefined,
    sort_by: sortBy = undefined,
    order = undefined,
    quotation_date = undefined,
    quotation_formatted_number = undefined,
    event = undefined,
    pro_invoice_date = undefined,
    sales_account_name = undefined,
    company_name = undefined,
    pro_invoice_number = undefined,
    invoice_number = undefined,
    type_of_customer = undefined,
    sales_person_name = undefined,
    duration = undefined,
    coa = undefined,
    start_date = undefined,
    end_date = undefined,
    filter = undefined,
  } = paramsList;

  useEffect(() => {
    const offset = Number(page - 1) * Number(rowsPerPage);
    const params = {
      limit: rowsPerPage,
      company: company?.id,

      offset,
      status,
      query: searchQuery,
      search: searchQuery,
      sort_by: sortBy,
      quotation_date,
      quotation_formatted_number,
      sales_account__sales_account_name: sales_account_name,
      customer_type: type_of_customer,
      event,
      sales_person__sales_person_name: sales_person_name,
      pro_invoice_date,
      sales_account_name,
      sales_company__company_name: company_name,
      pro_invoice_formatted_number: pro_invoice_number,
      invoice_formatted_number: invoice_number,
      type_of_customer,
      sales_person_name,
      coa,
      duration,
      start_date,
      end_date,
      category: filter,
      // category__id: filter,
      ...paramsList,
    };
    if (order === 'D') params.order_by = order;
    if (order === 'D') params.ordering = `-${sortBy}`;

    setParamsState(params);
  }, [searchParams, company]);

  return [paramsState, setParamsState];
}

export default useGetParams;
