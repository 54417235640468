import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanyBaseInfoQuery } from 'services/private/auth';
import { getCompanyDetail } from 'store/slices/authSlice';

import {
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_MANAGER,
  ACCOUNTING_STAFF_PAYABLE,
  ACCOUNTING_STAFF_RECEIVABLE,
  AUDIT_MANAGER,
  COMPANY_DETAILS,
  FINANCE_DEPT,
  HR_CONTROLLER,
  HR_DEPT,
  HR_MANAGER,
  HR_STAFF,
  OPERATIONS,
  OPERATIONS_STAFF,
  OPERATION_CONTROLLER,
  OPERATION_MANAGER,
  OPERTIONS_DMC,
  OPERTIONS_FIT,
  OPERTIONS_MEMBERS,
  SUPER_USER,
} from 'utilities/constants';

function useAuthInfo() {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
  } = useSelector(state => state.auth);
  const companyDetail = useMemo(() => COMPANY_DETAILS, []);
  const { data: companyBaseInfoData, isSuccess, refetch } = useCompanyBaseInfoQuery();

  const {
    user,
    companyDetails
  } = useSelector(state => state.auth);

  const groups = useMemo(() => user?.groups, [user]);
  const groupName = useMemo(
    () => user?.groups?.length > 0 && (user?.groups[0]?.name || null),
    [user]
  );
  const id = useMemo(() => user?.id, [user]);
  const email = useMemo(() => user?.email, [user]);

  const profile = useMemo(() => user?.profile, [user]);
  const isStaffPayable = useMemo(() => user?.isStaffPayable, [user]);
  const isStaffReceivable = useMemo(() => user?.isStaffReceivable, [user]);
  const isAccountingStaff = useMemo(() => user?.isAccountingStaff, [user]);
  const isAccountingSupervisor = useMemo(() => user?.isAccountingSupervisor, [user]);
  const allowedSections = useMemo(() => user?.allowed_permission, [user]);
  const allowedModules = useMemo(() => user?.allowed_sections, [user]);
  const isAccountingController = useMemo(() => groups && groups[0]?.name === 'accounting_controller', [user]);
  const isHRController = useMemo(() => groups && (groups[0]?.name === 'hr_controller' || 'hr'), [user]);
  const isGlobalAdminstrator = useMemo(() => groups && (groups[0]?.name === 'global_administrator'), [user]);
  const userController = useMemo(() => user?.groups?.[0]?.name, [user]);
  const department = useMemo(() => user?.profile?.department, [user]);
  // const allowSystemAccess = useMemo(() => user?.profile?.system_access, [user]);
  useEffect(() => {
    if (isAuthenticated && JSON.parse(localStorage.getItem('companyDetail')) !== null) {
      dispatch(getCompanyDetail(JSON.parse(localStorage.getItem('companyDetail'))));
    } else if (isAuthenticated && isSuccess && companyBaseInfoData) {
      dispatch(getCompanyDetail(companyBaseInfoData));
    }
  }, [isSuccess, companyBaseInfoData, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated]);
  const isInventory = useMemo(() => companyDetails?.is_inventory_allowed, [companyDetails]);

  const company = useMemo(() => companyDetails, [companyDetails]);
  const isOperationsStaff = useMemo(
    () => (groupName === OPERATION_CONTROLLER) ||
     (groupName === OPERATION_MANAGER) ||
     (groupName === OPERATIONS_STAFF) ||
     (groupName === OPERATIONS) ||
     (groupName === OPERTIONS_FIT) ||
     (groupName === OPERTIONS_MEMBERS) ||
     (groupName === OPERTIONS_DMC),
    [groupName]
  );

  const isAuditorStaff = useMemo(() => groupName === AUDIT_MANAGER, [groupName]);
  const isSuperUser = useMemo(() => groups && groups[0]?.name === SUPER_USER, [groups]);
  const isHrStaff = useMemo(() => groups && groups[0] === HR_STAFF, [groups]);
  const isAccountingDept = useMemo(
    () => isAccountingSupervisor || isAccountingStaff,
    [isAccountingSupervisor, isAccountingStaff]
  );

  const requestorSign = useMemo(() => user?.profile?.requestor_signature, [user]);
  const userDepartment = useMemo(() => (user ? user?.profile?.department : ''), [user]);
  const stampSignature = useMemo(() => companyDetails && companyDetails?.stamp_signature, [companyDetails]);
  const stamp = useMemo(() => companyDetails && companyDetails.stamp_signature?.stamp, [companyDetails]);
  const signature = useMemo(() => companyDetails && companyDetails.stamp_signature?.signature, [companyDetails]);
  const isAccountingDeptUser = useMemo(
    () => userDepartment === FINANCE_DEPT ||
      [
        ACCOUNTING_CONTROLLER,
        ACCOUNTING_MANAGER,
        ACCOUNTING_STAFF_PAYABLE,
        ACCOUNTING_STAFF_RECEIVABLE,
      ].includes(groupName),
    [userDepartment]
  );

  const isAccountingPerson = useMemo(() => isAccountingStaff || isAccountingSupervisor || isAccountingDeptUser || isAccountingDept);

  const isHRDeptUser = useMemo(
    () => userDepartment === HR_DEPT ||
      [HR_CONTROLLER, HR_MANAGER, HR_STAFF].includes(groupName),
    [userDepartment]
  );

  return {
    companyDetail,
    user,
    groups,
    id,
    email,
    profile,
    department,
    isStaffPayable,
    isStaffReceivable,
    isAccountingStaff,
    isAccountingSupervisor,
    isAuthenticated,
    allowedSections,
    allowedModules,
    // allowSystemAccess,
    isSuperUser,
    isHrStaff,
    groupName,
    isOperationsStaff,
    isAccountingDept,
    isInventory,
    requestorSign,
    isAccountingDeptUser,
    isHRDeptUser,
    stamp,
    signature,
    stampSignature,
    isAuditorStaff,
    company,
    isHRController,
    isGlobalAdminstrator,
    userController,
    isAccountingPerson,
    isAccountingController
  };
}

export default useAuthInfo;
