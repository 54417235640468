import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { debounce } from 'lodash';
// import { useNavigate } from 'react-router-dom';

// HOOKS & API & CONTEXT
import {
  useUpdateCheckOutMutation,
  useUpdateCheckinStatusMutation,
  useUpdateEndBreakTimeMutation,
  useUpdateStartBreakTimeMutation,
} from 'services/private/attendance';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { useAttendanceContext } from 'context/AttendanceContext';

// COMPONENTS & UTILS
import useAuthInfo from 'customHooks/useAuthInfo';
import CommonModal from 'containers/common/components/CommonModal';
import { useAddDailyReportsMutation, useUpdateSubmitReportMutation } from 'services/private/dailyReports';
import useGetParams from 'customHooks/useGetParams';
import AttendanceActionBtn from './AttendanceActionBtn';
import DailyReportForm from '../dailyReports/components/DailyReportForm';

function AttendanceActions() {
  const {
    shiftData,
    checkInData,
    checkInStatus,
    breakStatus,
    setCheckInStatus,
    setBreakStatus,
    toggleCheckInForm,
    dailyReportFormOpen,
    toggleDailyReportFormOpen,
    checkInDataLoading,
  } = useAttendanceContext();

  const { company } = useAuthInfo();
  const [apiParams] = useGetParams();

  // const navigate = useNavigate();

  const [updateSubmitReport,] =
    useUpdateSubmitReportMutation();

  const [addDailyReport,] =
    useAddDailyReportsMutation();

  const [updateCheckInStatus, { error, isSuccess, isLoading: checkInLoading }] =
    useUpdateCheckinStatusMutation();

  const [
    updateCheckOut,
    { error: checkOutError, isSuccess: checkOutSuccess, isLoading: checkOutLoading },
  ] = useUpdateCheckOutMutation();

  const [
    updateStartBreakTime,
    {
      error: startBreakError,
      isSuccess: startBreakSuccess,
      isLoading: startBreakLoading,
    },
  ] = useUpdateStartBreakTimeMutation();

  const [
    updateEndBreakTime,
    { error: endBreakError, isSuccess: endBreakSuccess, isLoading: endBreakLoading },
  ] = useUpdateEndBreakTimeMutation();

  useHandleApiResponse(error, isSuccess, 'Checked In Successfully!', '', false);
  useHandleApiResponse(checkOutError, checkOutSuccess, 'Checked Out Successfully!', '', false);
  useHandleApiResponse(startBreakError, startBreakSuccess, 'Break Started Successfully!', '', false);
  useHandleApiResponse(endBreakError, endBreakSuccess, 'Break Ended Successfully!', '', false);
  // useHandleApiResponse(
  //   addReportError,
  //   addReportSuccess,
  //   'Daily Report Added Successfully!',
  //   '',
  //   false
  // );
  // useHandleApiResponse(
  //   errorOfSubmit,
  //   successOfSubmit,
  //   'Daily Report Submitted Successfully!',
  //   '',
  //   false
  // );

  //   CONSTANTS
  const relaxationEnd = useMemo(() => {
    const shiftTime = moment(shiftData?.start_time, 'HH:mm:ss');
    const currentTime = moment();
    const currentDuration = currentTime.diff(shiftTime, 'minutes');
    const relaxationEndTime = shiftData?.relaxation_time;
    const timePassed = currentDuration > relaxationEndTime;
    return timePassed;
  }, [shiftData?.start_time]);

  const forgetToCheckIn = useMemo(
    () => relaxationEnd &&
      checkInData?.is_check_out &&
      checkInData?.date !== moment().format('YYYY-MM-DD'),
    [relaxationEnd, checkInData?.is_check_out, checkInData?.date]
  );

  const breakData = useMemo(() => {
    if (checkInData?.break_time) {
      const lastIndex = (checkInData?.break_time?.length ?? 1) - 1;
      const latestData = checkInData?.break_time[lastIndex];
      return latestData;
    }
    return {};
  }, [checkInData?.break_time]);

  const startBreakTime = useMemo(() => breakData?.start_break_time, [breakData?.start_break_time]);

  const endBreakTime = useMemo(() => breakData?.end_break_time, [breakData?.end_break_time]);

  //   HANDLERS
  const handleCheckIn = async () => {
    const timeFormatted = moment().format('HH:mm:00');
    const dateFormatted = moment().format('yyyy-MM-DD');
    await updateCheckInStatus({ date: dateFormatted, check_in_time: timeFormatted, company: company?.id });
    setCheckInStatus(true);
  };

  const handleCheckOut = async () => {
    if (checkInData?.daily_report?.length && checkInData?.is_report_submitted) {
      const timeFormatted = moment().format('HH:mm:00');
      const dateFormatted = moment().format('yyyy-MM-DD');
      await updateCheckOut({
        date: dateFormatted,
        check_out_time: timeFormatted,
        id: checkInData?.id,
      });
      setCheckInStatus(false);
    } else {
      toggleDailyReportFormOpen();
      // navigate('/attendance/daily-tasks-report?check_out=true');
    }
  };

  const handleSubmitReport = async () => {
    const res = await updateSubmitReport({
      is_report_submitted: true,
      id: checkInData?.id,
    });
    if (!res?.error && apiParams?.check_out) {
      const timeFormatted = moment().format('HH:mm:ss');
      const dateFormatted = moment().format('yyyy-MM-DD');
      await updateCheckOut({
        date: dateFormatted,
        check_out_time: timeFormatted,
        id: checkInData?.id,
      });
      // toggleDailyReportFormOpen();
    }

    const timeFormatted = moment().format('HH:mm:00');
    const dateFormatted = moment().format('yyyy-MM-DD');
    await updateCheckOut({
      date: dateFormatted,
      check_out_time: timeFormatted,
      id: checkInData?.id,
    });
    setCheckInStatus(false);
    // handleCheckOut();
    // setCheckInStatus(false);
  };

  // Debounce For Break Start
  const debouncedUpdateStartBreakTime = debounce(async (timeFormatted, attendanceId) => {
    const response = await updateStartBreakTime({
      start_break_time: timeFormatted,
      attendance: attendanceId,
    });
    if (!response?.error) {
      setBreakStatus(true);
    }
  }, 500); // Adjust the debounce delay as needed

  // Debounce For Break End
  const debouncedUpdateEndBreakTime = debounce(async (timeFormatted, breakId) => {
    const response = await updateEndBreakTime({
      end_break_time: timeFormatted,
      id: breakId,
    });
    if (!response?.error) {
      setBreakStatus(false);
    }
  }, 500);

  const handleBreakTime = async () => {
    const timeFormatted = moment().format('HH:mm:00');
    // Using Debounce For Break Button
    if (!breakStatus) {
      debouncedUpdateStartBreakTime(timeFormatted, checkInData?.id);
    } else {
      debouncedUpdateEndBreakTime(timeFormatted, breakData.id);
    }
  };

  //   EFFECTS
  useEffect(() => {
    if (checkInData && !checkInStatus) {
      setCheckInStatus(state => !state);
    }
  }, [isSuccess, checkInData]);

  useEffect(() => {
    if (startBreakTime && !endBreakTime) {
      setBreakStatus(true);
    } else {
      setBreakStatus(false);
    }
  }, [startBreakTime, endBreakTime]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        display="flex"
        alignItems="center"
        spacing={2}
        className="isOnlyMobile"
      >
        {checkInData && !checkInData?.is_check_out && (
        <AttendanceActionBtn
          isLoading={endBreakLoading || startBreakLoading}
          label={breakStatus ? 'End break' : 'Start break'}
          color={breakStatus ? 'success' : 'primary'}
          onClick={handleBreakTime}
        />
        )}
        <Box>
          {checkInData && !checkInData?.is_check_out ? (
            <AttendanceActionBtn
              isLoading={checkOutLoading || checkInDataLoading}
              label="Check Out"
              onClick={handleCheckOut}
            />
          ) : (
            <AttendanceActionBtn
              isLoading={checkInLoading || checkInDataLoading}
              label="Check In"
              onClick={handleCheckIn}
            />
          )}
        </Box>

        {checkInData ? (
          <Box>
            {forgetToCheckIn && (
            <AttendanceActionBtn
              isLoading={checkInLoading || checkInDataLoading}
              label="Forget to check-in"
              onClick={toggleCheckInForm}
            />
            )}
          </Box>
        ) : (
          <Box>
            {relaxationEnd && (
            <AttendanceActionBtn
              isLoading={checkInLoading || checkInDataLoading}
              label="Forget to check-in"
              onClick={toggleCheckInForm}
            />
            )}
          </Box>
        )}
      </Stack>

      <CommonModal
        isOpen={dailyReportFormOpen}
        toggle={toggleDailyReportFormOpen}
        title="Add Report"
      >
        <DailyReportForm
          afterRequestSend={toggleDailyReportFormOpen}
          handler={addDailyReport}
          responseHandler={resp => {
            if (!resp.error) {
              toggleDailyReportFormOpen();
            }
          }}
          attendanceID={checkInData?.id}
          handleSubmit={handleSubmitReport}
          checkInData={checkInData}
        />
      </CommonModal>
    </>
  );
}

export default AttendanceActions;
