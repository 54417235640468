import React from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'styles/common/calendar.scss';
import theme from 'styles/mui/theme';

import moment from 'moment';
import { Box } from '@mui/material';
import { CalendarMonth, ChevronLeft, ChevronRight } from '@mui/icons-material';

const localizer = momentLocalizer(moment);

function Event({ event }) {
  return (
    <Box>
      <div className="mt-1">
        <strong>{event.title}</strong>
      </div>
      {/* <div>
        <small>{event.description}</small>
      </div> */}
    </Box>
  );
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
};

function eventStyleGetter(event) {
  const primary = theme.palette.primary.main;
  let color;

  switch (event.priority) {
    case 'high':
      color = 'green';
      break;

    case 'gry':
      color = 'gray';
      break;
    case 'normal':
      color = 'purpule';
      break;

    case 'family':
      color = 'purpule';
      break;

    case 'non':
      color = primary; //  '#70bbfd';
      break;
    default:
      color = '$color-additional';
      break;
  }

  const style = {
    backgroundColor: color,
    border: 'none',
  };

  return {
    style,
  };
}

export default function CalendarComponent({ events, onSelectEvent }) {
  const formats = {
    dayFormat: (date, culture) => localizer.format(date, 'DD', culture),
  };
  return (
    <Box className="calendarWrapper">
      <Calendar
        localizer={localizer}
        events={events}
        views={['month', 'week', 'day']}
        popup
        formats={formats}
        step={60}
        timeslots={1}
        showMultiDayTimes
        onSelectEvent={onSelectEvent}
        eventPropGetter={eventStyleGetter}
        messages={{
          previous: <ChevronLeft />,
          next: <ChevronRight />,
          today: <CalendarMonth />,
        }}
        components={{
          event: Event,
        }}
      />
    </Box>
  );
}

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      priority: PropTypes.string,
    })
  ).isRequired,
  onSelectEvent: PropTypes.func,
};

CalendarComponent.defaultProps = {
  onSelectEvent: () => { },
};
