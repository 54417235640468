import { createSlice } from '@reduxjs/toolkit';
import {
  ACCOUNTING_STAFF_PAYABLE,
  ACCOUNTING_STAFF_RECEIVABLE,
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_MANAGER,
} from 'utilities/constants';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  user: null,
  companyDetail: {},
  getCompanyDetail: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoggedIn: (state, action) => {
      const userDetails = { ...action.payload };
      delete userDetails.token;

      localStorage.setItem('token', action.payload.token);

      return {
        ...state,
        isAuthenticated: true,
        user: userDetails.userInfo,
      };
    },
    getUserDetail: (state, action) => ({
      ...state,
      isAuthenticated: true,
      user: action.payload,
    }),
    onLoggedOut: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('companyDetail');
      // localStorage.removeItem('companyInfo');
      return { ...initialState };
    },
    getCompanyDetail: (state, action) => {
      const company = { ...action.payload };
      localStorage.setItem('companyDetail', JSON.stringify(action.payload));
      return { ...state, companyDetails: company };
    },

    USER_LOADING: state => ({ ...state, isLoading: true }),
    userLoaded: (state, action) => {
      const res = action.payload;
      const modifiedData = {
        ...res,
        isStaffPayable: res?.groups?.[0]?.name === ACCOUNTING_STAFF_PAYABLE,
        isStaffReceivable: res?.groups?.[0]?.name === ACCOUNTING_STAFF_RECEIVABLE,
        isAccountingStaff:
          res?.groups?.[0]?.name === ACCOUNTING_STAFF_PAYABLE ||
          res?.groups?.[0]?.name === ACCOUNTING_STAFF_RECEIVABLE,
        isAccountingSupervisor:
          res?.groups?.[0]?.name === ACCOUNTING_CONTROLLER ||
          res?.groups?.[0]?.name === ACCOUNTING_MANAGER,
      };

      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: modifiedData,
      };
    },
  },
});

export const { onLoggedIn, getUserDetail, onLoggedOut, getCompanyDetail, userLoaded } =
  authSlice.actions;
export default authSlice.reducer;
