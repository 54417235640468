import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  filters: {},
  isRecordExist: false,
  vatAuditData: [],
  isLoading: false,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getReports: (state, action) => ({ ...state, data: action.payload }),
    setReportsFilter: (state, action) => ({ ...state, filters: action.payload }),
    clearReportsData: state => ({
      ...state,
      data: null,
      vatAuditData: [],
    }),
  },
});

export const { setReportsFilter, clearReportsData } = reportsSlice.actions;
export default reportsSlice.reducer;
